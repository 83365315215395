export function useDownloadAllMarkets(eventId: Ref<number>) {
  const MS = useMarketsStore()
  const markets = useMarketsByEventId(eventId)

  watchEffect(async () => {
    if (!MS.systemStates.sbCacheInited || !MS.systemStates.fullActualLoaded)
      return

    try {
      MS.downloadAllMarkets(toValue(eventId))
    } catch (error) {
      console.error('error', error)
    }
  })

  return {
    markets,
  }
}
