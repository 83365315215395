import { parseLanguageMap } from '@st/utils'
import type { TSportEvent, TCompetitor } from 'markets-store/types'
import { getEventBackground } from '../../helpers'

interface ReturnEventInfo {
  eventHeaderBackground: ComputedRef<{
    background: string
  }>
  competitors: ComputedRef<{
    home: TCompetitor
    away: TCompetitor
  }>
  tournamentFullName: ComputedRef<string>
  isLive: ComputedRef<boolean>
  scores: ComputedRef<{
    home?: number
    away?: number
  }>
}

export function useEventInfo(event: Ref<TSportEvent>): ReturnEventInfo {
  const i18n = useI18n()

  const tournamentFullName = computed(() => {
    const sportName = parseLanguageMap(
      (event.value.sport?.name ?? '') as string,
      i18n.locale.value,
    )
    const tournamentName = parseLanguageMap(
      event.value.tournament?.name ?? '',
      i18n.locale.value,
    )
    return sportName ? `${sportName}, ${tournamentName}` : tournamentName
  })
  const competitors = computed(() => ({
    home: event.value.home ?? {},
    away: event.value.away ?? {},
  }))
  const eventHeaderBackground = computed(() => ({
    background: `var(${getEventBackground(event.value.sportId).header})`,
  }))
  const isLive = computed(() => !!event.value.status)
  const scores = computed(() => {
    const home = event.value?.match?.stats?.home_score ?? 0
    const away = event.value?.match?.stats?.away_score ?? 0

    return { home, away }
  })

  return {
    tournamentFullName,
    competitors,
    eventHeaderBackground,
    isLive,
    scores,
  }
}
